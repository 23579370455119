import ArrowRightIcon from './arrow-right.svg';
import CarouselArrowPreviousIcon from './carousel-arrow-previous.svg';
import CarouselArrowNextIcon from './carousel-arrow-next.svg';
import MedalStarIcon from './medal-star.svg';
import MapPinIcon from './map-pin.svg';
import TrendDownIcon from './fees/trend-down.svg';
import TrendUpIcon from './fees/trend-up.svg';
import CurrencyCircleDollarIcon from './fees/currency-circle-dollar.svg';
import SettingsIcon from './fees/settings.svg';
import ShieldSecurityIcon from './fees/shield-security.svg';
import InfoIcon from './info.svg';
import MinusIcon from './minus.svg';
import AddIcon from './add.svg';
import DollarBillIcon from './dollar-bill.svg';
import BtcIcon from './btc.svg';
import ArrowSwapHorizontalIcon from './arrow-swap-horizontal.svg';
import InteracLogoIcon from './interac-logo.svg';
import ChevronDownIcon from './chevron-down.svg';
import ChevronLeftIcon from './chevron-left.svg';
import ChevronRightIcon from './chevron-right.svg';
import BannerBackgroundIcon from './banner-background-icon.svg';
import CloseIcon from './close.svg';
import FacebookIcon from './socials/facebook.svg';
import InstagramIcon from './socials/instagram.svg';
import XIcon from './socials/twitter.svg';
import TermsOfServiceMapImage from './terms-of-service-map.svg';
import CheckIcon from './check.svg';
import DirectionsIcon from './directions.svg';
import DocumentCopyIcon from './document-copy.svg';
import MessageQuestionIcon from './message-question.svg';
import ConfirmedCircleIcon from './confirmed-circle.svg';
import ErrorCircleIcon from './error-circle.svg';
import LogoBigIcon from './logo-big.svg';
import OrderErrorImage from './order-error.svg';
import SpinnerIcon from './spinner.svg';

export {
  MedalStarIcon,
  ArrowRightIcon,
  CarouselArrowPreviousIcon,
  CarouselArrowNextIcon,
  MapPinIcon,
  CurrencyCircleDollarIcon,
  SettingsIcon,
  ShieldSecurityIcon,
  TrendDownIcon,
  TrendUpIcon,
  InfoIcon,
  MinusIcon,
  AddIcon,
  DollarBillIcon,
  BtcIcon,
  ArrowSwapHorizontalIcon,
  InteracLogoIcon,
  ChevronDownIcon,
  BannerBackgroundIcon,
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  XIcon,
  TermsOfServiceMapImage,
  CheckIcon,
  DirectionsIcon,
  DocumentCopyIcon,
  MessageQuestionIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ConfirmedCircleIcon,
  ErrorCircleIcon,
  LogoBigIcon,
  OrderErrorImage,
  SpinnerIcon,
};
